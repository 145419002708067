import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import Zoom from "react-medium-image-zoom";

const AutoSizeZoomImage = (props: { url: string }) => {
  const [toZoom, setToZoom] = useState(false);
  const minSizeToZoom = 0; //700; //! always zoom in

  useEffect(() => {
    let img = new Image();
    const imgLoadFunction = () => {
      // console.log(8889, img.width, img.height);
      if (img.width > minSizeToZoom || img.height > minSizeToZoom) {
        setToZoom(true);
      }
    };
    // img.onload = imgLoadFunction;
    img.addEventListener("load", imgLoadFunction);
    img.src = props.url;

    return () => {
      img.removeEventListener("load", imgLoadFunction);
    };
  }, []);

  const child = (
    <Grid
      container
      justifyContent="center"
      style={{ alignItems: "flex-start", textAlign: "center" }}
    >
      <img src={props.url} className="article-image-fitter" />
    </Grid>
  );

  if (!toZoom) {
    return child;
  }

  return <Zoom children={child} />;
};

export default AutoSizeZoomImage;
