/** @jsxImportSource @emotion/react */
import {
  Box,
  Button,
  Grid,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import React from "react";
import "react-medium-image-zoom/dist/styles.css";
import { LocalizedLink } from "../../components/LocalizedLink";
import SEO from "../../components/old/seo";
import Footer from "../../components/shared/Footer/Footer";
import MissingContent from "../../components/shared/MissingContent/MissingContent";
import ArrowArticleIcon from "./theme/ArrowArticleIcon";
import { DateTime } from "luxon";
import "../../styles/blog.css";
import ArticleBody from "./components/ArticleBody";
import AuthorBadge from "./components/AuthorBadge";
import { l_blogarticle } from "./locales";
import ArrowArticleIconOrangePrev from "./theme/ArrowArticleIconOrangePrev";
import ArrowArticleIconOrangeSucc from "./theme/ArrowArticleIconOrangeSucc";
import StyledOrangeButtonWithIcon from "./components/StyledOrangeButtonWithIcon";
import AllArticlesButton from "./components/AllArticlesButton";

const Article = (props: {
  data: {
    contentfulArticle: ContentfulArticle;
    allContentfulArticle: { edges: { node: ContentfulArticle }[] };
  };
}) => {
  const article = props.data.contentfulArticle;
  const allArticles = props.data.allContentfulArticle.edges.map((e) => e.node);

  const title = article.title;
  const description = article.metaDescription;
  const datePublished = article.publishedAt;
  const dateModified = article.updatedAt;
  const image = article.foregroundImage?.file?.url
    ? article.foregroundImage.file.url
    : null;

  const currentArticleIndex = allArticles
    .map((singleArticle) => singleArticle.id)
    .indexOf(article.id);

  const nextArticle =
    allArticles[(currentArticleIndex + 1) % allArticles.length];

  const prevArticle =
    allArticles[currentArticleIndex - 1] ?? allArticles[allArticles.length - 1];

  const articleSEO: {
    headline: string;
    image: any;
    modified_time?: string;
    published_time?: string;
  } = {
    headline: title,
    image: image,
  };

  if (dateModified) {
    articleSEO["modified_time"] = dateModified;
  }

  if (datePublished) {
    articleSEO["published_time"] = datePublished;
  }

  if (
    !article.body ||
    (!article.isVisible && process.env.NODE_ENV === "production")
  ) {
    return (
      <React.Fragment>
        <SEO noIndex={true} />
        <MissingContent />
        <Footer />
      </React.Fragment>
    );
  }

  const theme = useTheme();
  const isOverMd = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <React.Fragment>
      <SEO
        title={title}
        description={description}
        image={image}
        article={articleSEO}
      />

      <Grid
        container
        item
        justifyContent="center"
        xs={12}
        sx={{
          // margin: "0px 20px",
          "h1, h2, h3, b": { color: "#000" },
          // backgroundColor: "yellow",
          marginTop: { md: "120px", xs: "40px" },
          marginBottom: { md: "320px", xs: "100px" },
        }}
      >
        <div className="blog-article-body-container">
          {isOverMd && (
            <LocalizedLink to="/blog" style={{ textDecoration: "none" }}>
              <Button
                variant="text"
                disableRipple
                startIcon={
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "-2px",
                      marginRight: "8px",
                    }}
                  >
                    <ArrowArticleIcon />
                  </Box>
                }
                sx={{
                  transition: (theme) =>
                    theme.transitions.create("background-color"),
                  padding: "8px 16px",
                  borderRadius: "32px",
                  backgroundColor: "transparent",
                  alignItems: "center",
                  ":hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.06)",
                  },
                  ":active": {
                    backgroundColor: "rgba(0, 0, 0, 0.12)",
                  },
                }}
              >
                <Typography
                  sx={{
                    color: "black",
                    fontSize: "16px",
                    lineHeight: "16px",
                    fontWeight: "700 !important",
                  }}
                >
                  {l_blogarticle("topSide.buttonText")}
                </Typography>
              </Button>
            </LocalizedLink>
          )}
          <Typography
            component="h2"
            children={article.title}
            sx={{
              marginBottom: { md: "56px !important", xs: "16px !important" },
            }}
          />
          <Typography
            sx={{
              fontSize: { md: "16px !important", xs: "10px !important" },
              lineHeight: { md: "20px !important", xs: "12px !important" },
              marginBottom: { md: "16px", xs: "8px" },
            }}
          >
            {DateTime.fromISO(datePublished).toFormat("dd.MM.yyyy")}
          </Typography>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              gap: { md: "16px", xs: "16px" },
              marginBottom: { md: "88px", xs: "48px" },
            }}
          >
            <AuthorBadge author={article.author} />
            {article.authors
              ? article.authors.map((auth) => <AuthorBadge author={auth} />)
              : null}
          </Box>

          {article.subtitle ? (
            <p children={article.subtitle} className="blog-article-subtitle" />
          ) : null}

          {article.foregroundImage &&
          article.hideForegroundImageInside !== true ? (
            <Img
              className="blog-article-cover-image"
              fluid={article.foregroundImage.fluid}
            />
          ) : null}

          <div
            style={{
              paddingTop: 50,
              paddingBottom: 25,
              // marginBottom: 50,
              // borderBottom: "1px solid rgba(0, 0, 0, 0.15)"
            }}
            children={<ArticleBody article={article} />}
          />

          <Box sx={{ marginTop: { md: "200px", xs: "80px" } }}>
            <Stack
              sx={{
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                maxWidth: "1000px",
                margin: "auto",
              }}
            >
              <LocalizedLink
                to={`/blog/${prevArticle.category.title}/${prevArticle.slug}/`}
                style={{ textDecoration: "none" }}
              >
                <StyledOrangeButtonWithIcon
                  startIcon={<ArrowArticleIconOrangePrev />}
                  children={l_blogarticle("bottomSide.prevButton")}
                />
              </LocalizedLink>

              {isOverMd ? <AllArticlesButton /> : null}

              <LocalizedLink
                to={`/blog/${nextArticle.category.title}/${nextArticle.slug}/`}
                style={{ textDecoration: "none" }}
              >
                <StyledOrangeButtonWithIcon
                  endIcon={<ArrowArticleIconOrangeSucc />}
                  children={l_blogarticle("bottomSide.succButton")}
                />
              </LocalizedLink>
            </Stack>
          </Box>
        </div>
      </Grid>

      <Footer />
    </React.Fragment>
  );
};

export default Article;

export const pageQuery = graphql`
  query Article($slug: String!, $localeRegex: String) {
    contentfulArticle(
      slug: { eq: $slug }
      node_locale: { regex: $localeRegex }
    ) {
      id
      slug
      title
      subtitle
      isVisible
      metaDescription
      category {
        title
        fullname
      }
      hideForegroundImageInside
      foregroundImage {
        fluid(maxWidth: 1000, quality: 95) {
          ...GatsbyContentfulFluid_withWebp
          ...GatsbyContentfulFluid
        }
        file {
          url
        }
      }
      body {
        body
      }
      updatedAt
      publishedAt
      author {
        name
        surname
        role
        description {
          description
        }
        profileImage {
          fluid(maxWidth: 300, quality: 95) {
            ...GatsbyContentfulFluid_withWebp
            ...GatsbyContentfulFluid
          }
        }
      }
      authors {
        name
        surname
        role
        description {
          description
        }
        profileImage {
          fluid(maxWidth: 300, quality: 95) {
            ...GatsbyContentfulFluid_withWebp
            ...GatsbyContentfulFluid
          }
        }
      }
    }

    allContentfulArticle(
      filter: { node_locale: { regex: $localeRegex }, isVisible: { eq: true } }
      sort: { order: DESC, fields: publishedAt }
    ) {
      edges {
        node {
          id
          slug
          title
          subtitle
          isVisible
          updatedAt
          publishedAt
          category {
            title
            fullname
          }
          foregroundImage {
            fluid(maxWidth: 1000, quality: 95) {
              ...GatsbyContentfulFluid_withWebp
              ...GatsbyContentfulFluid
            }
          }
          author {
            name
            surname
            role
          }
        }
      }
    }
  }
`;

export interface ContentfulArticleAuthor {
  name: string;
  surname: string;
  role: string;
  description: {
    description: string;
  };
  profileImage: any;
}

export interface ContentfulArticle {
  id: string;
  slug: string;
  title: string;
  subtitle: string;
  isVisible: boolean;
  metaDescription: string;
  category: {
    title: string;
    fullname: string;
  };
  hideForegroundImageInside: boolean;
  foregroundImage: any;
  body: {
    body: string;
  };
  updatedAt: string;
  publishedAt: string;
  author: ContentfulArticleAuthor;
  authors: ContentfulArticleAuthor[];
}

export interface ContentfulArticleBodyElement {
  type: "img" | "a";
  props?: {
    "data-description"?: string;
    "data-file"?: any;
    href?: string;
    children?: ContentfulArticleBodyElement[];
  };
}
